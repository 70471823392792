import { FC } from 'react';

import { Flex, Spin } from 'antd';
import { SpinProps } from 'antd/lib';
import { LoadingOutlined } from '@ant-design/icons';

import styles from './Spinner.module.scss';

interface ISpinner extends SpinProps {
  type?: 'local' | 'global';
  showTip?: boolean;
}

export const Spinner: FC<ISpinner> = ({ type = 'local', showTip = false, tip = 'Загрузка...', ...props }) => {
  if (type === 'global') {
    return (
      <div className={styles.globalSpin}>
        <Flex vertical gap={30}>
          <Flex justify="center">
            <LoadingOutlined className={styles.globalSpinIcon} />
          </Flex>
          <div className={styles.globalSpinText}>{tip}</div>
        </Flex>
      </div>
    );
  }

  return <Spin {...props} tip={showTip ? tip : undefined} indicator={<LoadingOutlined />} />;
};
