export const commonUrls = {
  main: '/',
  notFound: '/*',
  inDev: '/in-dev',

  jobProfiles: '/job-profiles',
  assessments: '/assessment',
  assessment: '/assessment/:status/:id',
  myReports: '/my-reports',
  myReport: '/my-reports/:id',
};

export const hrUrls = {
  reports: '/reports',
  questionnaires: '/questionnaires',
  employees: '/employees',

  report: '/reports/:id',
  employee: '/employees/:id',

  questionnaire: '/questionnaires/:questionnaireType',
  competenciesAndIndicators: '/questionnaires/competencies-and-indicators',
  competenciesAndIndicatorsForm: '/questionnaires/competencies-and-indicators/form',

  ...commonUrls,
};

export const employeeUrls = {
  ...commonUrls,
};

export const supervisorUrls = {
  subordinates: '/subordinates',
  subordinate: '/subordinates/:id',

  ...commonUrls,
};
