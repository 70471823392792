import ky from 'ky';

import { LS_ORG_ID_KEY } from '@constants/localStorageKeys';
import { getCookie } from '@utils/getCookie';
import { getOrgId } from '@utils/getOrgId';

import { EngineResponse } from './common.types';
import { QUIZER_ERRORS_BY_KEY } from './quizerErrors';

export const api360 = ky.create({
  prefixUrl: `${process.env.REACT_APP_360_API}/api`,
  hooks: {
    beforeRequest: [
      (request) => {
        request.headers.set('Authorization', getCookie('_utid') || process.env.REACT_APP_LOCAL_TOKEN || '');
        request.headers.set('x-org-id', getOrgId());
      },
    ],
    afterResponse: [
      async (_request, _options, response) => {
        if (response.status === 401) {
          window.unione.goToAuth();
          localStorage.removeItem(LS_ORG_ID_KEY);
        }

        return response;
      },
    ],
  },
});

export const api360Manager = api360.extend({
  prefixUrl: `${process.env.REACT_APP_360_MANAGER_API}/api`,
});

export const quizer360 = ky.create({
  prefixUrl: process.env.REACT_APP_360_QUIZER,
  hooks: {
    beforeRequest: [
      (request) => {
        request.headers.set('Authorization', getCookie('_utid') || process.env.REACT_APP_LOCAL_TOKEN || '');
      },
    ],
    afterResponse: [
      async (_request, _options, response) => {
        if (!String(response.status).startsWith('2')) {
          throw new Error(QUIZER_ERRORS_BY_KEY[99]);
        }

        const engineResponse: EngineResponse<unknown> = await response.json();

        if (!engineResponse.status) {
          const error = engineResponse.errors[0];
          const quizerError = QUIZER_ERRORS_BY_KEY[error.key] || error.message;

          throw new Error(quizerError, {
            cause: {
              errorKey: error.key,
            },
          });
        }
      },
    ],
  },
});
