import { makeAutoObservable, onBecomeObserved, runInAction } from 'mobx';

import { Role, StoreData } from '@api/common.types';
import { User, userApi } from '@api/userApi';
import { LS_ORG_ID_KEY } from '@constants/localStorageKeys';
import { getOrgId } from '@utils/getOrgId';
import { requestController } from '@utils/requestController';

import { RoleRu } from '../api/organizationApi/organizationApi.types';

class UserStore {
  public token: StoreData<string> = {
    loading: false,
  };

  public user: StoreData<User> = {
    loading: false,
  };

  public organizationId = getOrgId();

  constructor() {
    makeAutoObservable(this);
    onBecomeObserved(this, 'user', this.getUser);
    onBecomeObserved(this, 'token', this.getToken);
  }

  public getToken = async () => {
    try {
      this.token.loading = true;
      this.token.error = undefined;

      if (process.env.REACT_APP_LOCAL_TOKEN) {
        // Имитация получение токена
        const localToken = new Promise<string>((resolve) => {
          setTimeout(() => {
            resolve(process.env.REACT_APP_LOCAL_TOKEN as string);
          }, 1000);
        });

        this.token.data = await localToken;

        return;
      }

      if (window.unione) {
        const unioneToken = await window.unione.getUnioneToken();

        if (unioneToken) {
          runInAction(() => {
            this.token.data = unioneToken;
          });
        }
      }
    } finally {
      runInAction(() => {
        this.token.loading = false;
      });
    }
  };

  public getUser = () => {
    this.user.loading = true;

    return requestController(() => userApi.getMe(), {
      successCallback: (data) => {
        this.user.data = data;
        this.user.loading = false;
      },
      finallyCallback: () => {
        this.user.loading = false;
      },
    });
  };

  public setOrgId = (id: string) => {
    this.organizationId = id;
    localStorage.setItem(LS_ORG_ID_KEY, id);
  };

  public get userRole() {
    return this.user.data?.roles.find((el) => el.organization_id === this.organizationId)?.role;
  }

  public get roleOptions() {
    const rolesMap = (this.user.data?.roles || []).reduce((acc, value) => {
      const { role, organization } = value;

      // @ts-ignore
      acc[role] = [...(acc[role] || []), { label: organization.name, value: organization._id }];

      return acc;
    }, {});

    return Object.entries(rolesMap).map(([role, options]) => ({
      label: RoleRu[role as Role],
      options,
    }));
  }
}

export const userStore = new UserStore();
