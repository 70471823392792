import { theme as antTheme, ThemeConfig } from 'antd';

export const theme: ThemeConfig = {
  token: {
    colorPrimary: '#0071CE',
    fontFamily: 'IBM Plex Sans',
    colorText: '#3B4168',
    fontSize: 14,
    fontWeightStrong: 400,
    colorError: '#EB5757',
    colorInfo: '#0071CE',
    colorInfoBg: '#EFF8FE',
    colorInfoBorder: '#EFF8FE',
  },
  components: {
    Typography: {
      fontFamily: 'Montserrat',
      colorTextHeading: '#3B4168',
      fontWeightStrong: 700,
      titleMarginBottom: '16px',
    },
    Modal: {
      titleColor: '#3B4168',
      titleFontSize: 24,
      fontFamily: 'Montserrat',
      fontWeightStrong: 700,
    },
  },
  algorithm: antTheme.defaultAlgorithm,
};
