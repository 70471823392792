import { FC, PropsWithChildren } from 'react';

import { observer } from 'mobx-react-lite';

import { Button, Result } from 'antd';

import { Spinner } from '@components/Spinner';
import { userStore } from '@stores/UserStore';

export const Auth: FC<PropsWithChildren> = observer(({ children }) => {
  const {
    token: { data, loading },
  } = userStore;

  if (loading) {
    return <Spinner type="global" tip="Проверка авторизации..." />;
  }

  if (!data) {
    return (
      <Result
        status="500"
        title="Вы не авторизованны."
        subTitle="Пожалуйста перейдите на страницу авторизации."
        extra={
          <Button type="primary" onClick={() => window.unione.goToAuth()}>
            Перейти на страницу авторизации
          </Button>
        }
      />
    );
  }

  return <>{children}</>;
});
