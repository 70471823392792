import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { observer } from 'mobx-react-lite';

import { Button, Flex, Form, Select } from 'antd';

import { commonUrls } from '@routes/appUrls';
import { userStore } from '@stores/UserStore';

import styles from './SelectRole.module.scss';

export const SelectRole = observer(() => {
  const { roleOptions, setOrgId } = userStore;
  const [organizationId, setOrganizationId] = useState();
  const navigate = useNavigate();

  const handleClick = () => {
    if (organizationId) {
      setOrgId(organizationId);
      navigate(commonUrls.main);
    }
  };

  return (
    <Flex className={styles.selectRoleContainer} justify="center" align="center">
      <Form layout="vertical">
        <Form.Item label="Пожалуйста, выберете роль:">
          <Select className={styles.select} options={roleOptions} value={organizationId} onChange={setOrganizationId} />
        </Form.Item>
        <Form.Item>
          <Button onClick={handleClick} disabled={!organizationId} type="primary" htmlType="submit">
            Войти
          </Button>
        </Form.Item>
      </Form>
    </Flex>
  );
});
