import { HTTPError } from 'ky';

import { notification } from 'antd';

import { EngineErrorKeys } from '@api/common.types';

export interface RequestControllerOptions {
  showError?: boolean;
}

interface Cause {
  errorKey: EngineErrorKeys;
}

interface CallbackFunctionsType<T> {
  successCallback?: (response: T) => void;
  finallyCallback?: () => void;
  errorCallback?: (error: HTTPError | Error, cause: Cause) => void;
}

export const requestController = async <T>(
  promiseFunction: () => Promise<T>,
  callbackFunctions: CallbackFunctionsType<T> = {},
  options: RequestControllerOptions = {
    showError: true,
  },
) => {
  const { successCallback, errorCallback, finallyCallback } = callbackFunctions;

  try {
    const data = await promiseFunction();

    if (successCallback) {
      successCallback(data);
    }
  } catch (error) {
    const err = error as HTTPError | Error;

    const { message } = err instanceof HTTPError ? await err.response.json() : (err as Error);

    if (errorCallback) {
      errorCallback(err, err.cause as Cause);
    }

    if (options.showError) {
      notification.error({
        message,
      });
    }
  } finally {
    if (finallyCallback) {
      finallyCallback();
    }
  }
};
