import { NavigateFunction, useNavigate } from 'react-router-dom';

// eslint-disable-next-line import/no-mutable-exports
export let globalNavigate: NavigateFunction;

export const GlobalNavigate = () => {
  globalNavigate = useNavigate();

  return null;
};
