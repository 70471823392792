import { BrowserRouter } from 'react-router-dom';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { App as AntApp, ConfigProvider } from 'antd';
import locale from 'antd/locale/ru_RU';

import { AppRoutes } from '@routes/router';

import { GlobalNavigate } from './GlobalNavigate';
import { theme } from './theme';

import 'antd/dist/reset.css';
import 'dayjs/locale/ru';

dayjs.extend(utc);

const App = () => (
  <ConfigProvider locale={locale} theme={theme}>
    <BrowserRouter>
      <AntApp>
        <AppRoutes />
      </AntApp>
      <GlobalNavigate />
    </BrowserRouter>
  </ConfigProvider>
);

export default App;
