import { RouteObject, useRoutes } from 'react-router-dom';

import { Access } from '@components/Access';
import { Auth } from '@components/Auth';

const appRoutes: RouteObject[] = [
  {
    path: '/*',
    element: (
      <Auth>
        <Access />
      </Auth>
    ),
  },
];

export const AppRoutes = () => useRoutes(appRoutes);
