/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum Role {
  Hr = 'hr',
  Supervisor = 'head',
  Employee = 'worker',
}

export enum TableOrderSort {
  ascend = '1',
  descend = '-1',
}

export enum Level {
  Basic = 'basic_level',
  Normal = 'normal_level',
  Advanced = 'advanced_level',
  Expert = 'expert_level',
}

export type SearchParams = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export interface StoreData<TData> {
  data?: TData;
  loading: boolean;
  error?: string;
}

export interface ListStoreData<TData> {
  data: TData[];
  loading: boolean;
  total: number;
  error?: string;
  params?: SearchParams;
}

export interface OneId {
  id: string;
  name: string;
  sur_name: string;
  patronymic: string;
  phone: string;
  avatar: string;
  email: string;
}

export interface List<TData> {
  items: TData[];
  totalCount: number;
}

export interface IOption {
  label: string;
  value: string;
}

export type EngineErrorKeys =
  | 99
  | 101
  | 102
  | 103
  | 104
  | 105
  | 106
  | 107
  | 108
  | 109
  | 110
  | 111
  | 112
  | 113
  | 114
  | 115
  | 116
  | 117
  | 118
  | 119
  | 120
  | 121
  | 122
  | 123
  | 124
  | 125
  | 126
  | 127
  | 128
  | 129
  | 130
  | 131
  | 132
  | 133
  | 134
  | 135
  | 136
  | 137
  | 138
  | 139;

export interface EngineError {
  key: EngineErrorKeys;
  message: string;
}

export interface EngineResponse<TData> {
  errors: EngineError[];
  need_fields: boolean;
  status: boolean;
  tm_req: string;
  values: [TData];
}

export enum QuestionnairesStatus {
  'process' = 'process',
  'finished' = 'finished',
  'available' = 'available',
  'not_finished' = 'not_finished',
  'closed' = 'closed',
}

export enum QuestionnairesType {
  self_worker = 'self_worker',
  worker_worker = 'worker_worker',
  head_worker = 'head_worker',
  worker_head = 'worker_head',
  head_head = 'head_head',
  self_head = 'self_head',
}

export enum QuestionnairesTypeRu {
  'self_worker' = 'Самооценка сотрудника',
  'worker_worker' = 'Оценка сотрудником смежного',
  'head_worker' = 'Оценка руководителем сотрудника',
  'worker_head' = 'Оценка сотрудником руководителя',
  'head_head' = 'Оценка руководителем руководителя',
  'self_head' = 'Самооценка руководителя',
}
