/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import { Level, Role } from '@api/common.types';
import { FetchForDate } from '@api/reportApi';
import { IFile } from '@api/uploadApi';

export interface GetEmployeeListParams {
  page: number;
  limit: number;
  all?: boolean;
  name?: string;
  email?: string;
  role?: Omit<Role, Role.Supervisor>;
  for_employee_id?: string;
  excludeHead?: boolean;
}

export interface EmployeeOneId {
  id: string;
  avatar: string;
  birthday: string;
  email: string;
  fio: string;
  phone: string;
}

export interface EmployeeOrganization {
  _id: string;
  address: string;
  email: string;
  name: string;
  phone: string;
  status: string;
  updated_at: string;
  created_at: string;
}

export enum EmployeeStatus {
  active = 'active',
  deleted = 'deleted',
}

export interface Employee {
  _id: string;
  oneid: EmployeeOneId;
  organization: EmployeeOrganization;
  role: Role;
  updated_at: string;
  created_at: string;
  status: EmployeeStatus;
}

export interface EmployeeCompetency {
  _id: string;
  title: string;
}

export interface EmployeeInfoSkill {
  competency: EmployeeCompetency;
  req_level: Level;
  real_level: Level;
  position_id: string;
  updated_at: string;
  created_at: string;
}

export interface EmployeeInfoPosition {
  _id: string;
  name: string;
}

export interface EmployeeInfo extends Employee {
  positions: EmployeeInfoPosition[];
  hard_skills: EmployeeInfoSkill[];
  soft_skills: EmployeeInfoSkill[];
}

export enum RoleRu {
  'hr' = 'HR',
  'worker' = 'Сотрудник',
  'head' = 'Руководитель',
}

export interface ChangeEmployeeRoleParams {
  id: string;
  role: Role;
  active: boolean;
}

export interface RemoveEmployeeParams {
  id_auth: string;
}

export enum IPRStatus {
  'created' = 'created',
  'processing' = 'processing',
  'to_processing' = 'to_processing',
  'need_approve' = 'need_approve',
  'need_finish' = 'need_finish',
  'need_cancel' = 'need_cancel',
  'deleted' = 'deleted',
  'rejected' = 'rejected',
  'canceled' = 'canceled',
  'overdue' = 'overdue',
  'finished' = 'finished',
}

export enum IPRStatusRu {
  'created' = 'Создано',
  'processing' = 'В работе',
  'to_processing' = 'В работе',
  'need_approve' = 'На утверждении',
  'need_finish' = 'Ожидает подтверждения',
  'need_cancel' = 'Ожидает подтверждения',
  'deleted' = 'Удалено',
  'rejected' = 'Отклонено',
  'canceled' = 'Отменено',
  'overdue' = 'Просрочено',
  'finished' = 'Завершено',
}

export interface GetEmployeeIPRParams {
  page: number;
  limit: number;
  auth_id?: string;
  org_id?: string;
  sort?: string;
  title?: string;
  stage?: IPRStatus;
}

export interface IPREmployeeItem {
  _id: string;
  actions: string;
  competency: EmployeeCompetency;
  end_date: string;
  format: string;
  files: IFile[];
  status: IPRStatus;
  perc: number;
  req_result: string;
  updated_at: string;
  created_at: string;
}

export interface IAddIPR {
  _id?: string;
  id_auth?: string;
  head_id?: string;
  organization_id?: string;
  competency_id: string;
  actions: string;
  req_result: string;
  format: string;
  end_date: string;
  status?: IPRStatus;
}

export interface IWorkerIPRConfirmParams {
  id: string;
  stage: IPRStatus;
  decision?: string;
  files?: IFile[];
}

export interface IUpdateIPRApprovalParams {
  id: string;
  stage: IPRStatus;
  decision?: string;
}

export interface ChangedCompetencyProfileSkill {
  competency_id: string;
  position_id: string;
  real_level: Level;
}

export interface IChangeCompetencyProfile {
  empId?: string;
  hard_skills: ChangedCompetencyProfileSkill[];
  soft_skills: ChangedCompetencyProfileSkill[];
}

export interface GetSubordinateParams {
  page: number;
  limit: number;
  org_id?: string;
  created_at?: FetchForDate;
  finished_at?: FetchForDate;
  fio?: string;
  head_id?: string;
}

export interface SubordinateHead {
  _id: string;
  fio: string;
}

export interface Subordinate {
  _id: string;
  fio: string;
  heads: SubordinateHead[];
  role: Role;
  email: string;
  phone: string;
  created_at: string;
  updated_at: string;
}

export interface getIPRHistoryParams {
  id: string;
  page: number;
  limit: number;
}

interface IPRHistoryUser {
  id: string;
  fio: string;
}

interface IPRHistory {
  id: string;
  format: string;
  files: IFile[];
  status: IPRStatus;
  result: string;
  actions: string;
  user: IPRHistoryUser;
  head: IPRHistoryUser;
}

export interface IPRHistoryItem {
  ipr: IPRHistory;
  author: IPRHistoryUser;
  comment: string;
  from_status: IPRStatus;
  to_status: IPRStatus;
  file: IFile;
  created_at: string;
}
