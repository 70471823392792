import { FC, lazy, Suspense } from 'react';

import { observer } from 'mobx-react-lite';

import { Result } from 'antd';

import { Role } from '@api/common.types';
import { SelectRole } from '@components/SelectRole';
import { Spinner } from '@components/Spinner';
import { userStore } from '@stores/UserStore';

const HrPages = lazy(() => import('@modules/HR'));
const SupervisorPages = lazy(() => import('@modules/Supervisor'));
const EmployeePages = lazy(() => import('@modules/Employee'));

export const Access: FC = observer(() => {
  const {
    organizationId,
    user: { data, loading },
  } = userStore;

  if (loading) {
    return <Spinner type="global" tip="Проверка прав доступа..." />;
  }

  if (data) {
    const role = data.roles.find((role) => role.organization_id === organizationId)?.role;

    if (!organizationId || (data.roles.length && !role)) {
      return <SelectRole />;
    }

    if (role === Role.Hr) {
      return (
        <Suspense fallback={<Spinner type="global" />}>
          <HrPages />
        </Suspense>
      );
    }

    if (role === Role.Supervisor) {
      return (
        <Suspense fallback={<Spinner type="global" />}>
          <SupervisorPages />
        </Suspense>
      );
    }

    if (role === Role.Employee) {
      return (
        <Suspense fallback={<Spinner type="global" />}>
          <EmployeePages />
        </Suspense>
      );
    }
  }

  return (
    <Result
      status="403"
      title="Упс! У вас нет доступа к этой странице."
      subTitle="Извините, у вас нет доступа к этой странице. Пожалуйста, свяжитесь с администратором, чтобы получить доступ."
    />
  );
});
